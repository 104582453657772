var AppFooter = /** @class */ (function () {
    function AppFooter() {
        console.log("[create] AppFooter");
        this._$root = $("#js-app-footer");
        this._$win = $(window);
        this._$doc = $(document);
    }
    AppFooter.prototype.setup = function () {
        console.log("[call] AppFooter.setup");
    };
    AppFooter.prototype.teardown = function () {
        console.log("[call] AppFooter.teardown");
    };
    return AppFooter;
}());
export default AppFooter;
