import App from "@/components/app";
import Loading from "@/components/loading";
var loadingElem = document.getElementById("js-loading");
if (loadingElem) {
    new Loading(loadingElem).setup();
}
var appElem = document.getElementById("app");
if (appElem) {
    new App(appElem).setup();
}
