import { Linear, TweenLite, TweenMax } from "gsap";
import { Swiper } from "swiper";
var CarouselCharacter = /** @class */ (function () {
    function CarouselCharacter() {
    }
    CarouselCharacter.prototype.setup = function () {
        var _this = this;
        this._$carouselCharacter = $("#js-carousel-character");
        if (this._$carouselCharacter.length === 0) {
            return;
        }
        console.log("[call] CarouselCharacter.setup");
        this._$characterThumbnail = $(".character__thumbnail");
        this._$characterProfile = $(".character__profile");
        this._$characterChange = $("#js-character-change");
        this._$characterChangeInner = this._$characterChange.find(".character__change-inner");
        this._$characterOverlay = $("#js-character-overlay");
        this._$characterSmoke = $(".character__smoke");
        this._carouselCharacter = new Swiper(this._$carouselCharacter[0], {
            slidesPerView: 1,
            centeredSlides: true,
            loop: true,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            autoplay: false,
        });
        this._carouselCharacter.slideTo(this._getIndexAtHash());
        this._carouselCharacter.on("slideChange", function () {
            _this._changeHash(_this._$characterProfile
                .eq(_this._carouselCharacter.realIndex)
                .data("name"));
        });
        var self = this;
        // tslint:disable-next-line:only-arrow-functions
        this._$characterThumbnail.on("click", function () {
            self._changeHash($(this).data("name"));
        });
        $(window).on("hashchange", function () {
            _this._slideToHash();
            _this._toggleChange();
            // TweenLite.to(window, 0.3, { scrollTo: 0 });
        });
        this._$characterChange.on("click", function () {
            _this._fadeInOverlay();
            _this._smokeIn();
        });
        this._$characterChange.on("mouseenter", function () {
            TweenLite.killTweensOf(_this._$characterChangeInner);
            TweenMax.to(_this._$characterChangeInner, 5, { rotation: "+=360", repeat: -1, ease: Linear.easeNone });
        });
        this._$characterChange.on("mouseleave", function () {
            TweenLite.killTweensOf(_this._$characterChangeInner);
            TweenMax.to(_this._$characterChangeInner, 15, { rotation: "+=360", repeat: -1, ease: Linear.easeNone });
        });
        TweenMax.to(this._$characterChangeInner, 15, { rotation: 360, repeat: -1, ease: Linear.easeNone });
        TweenLite.set(this._$characterSmoke, { autoAlpha: 0, rotation: 0 });
        this._toggleChange();
    };
    CarouselCharacter.prototype.teardown = function () {
        this._carouselCharacter.destroy(true, true);
    };
    CarouselCharacter.prototype._changeHash = function (value) {
        location.hash = value;
    };
    CarouselCharacter.prototype._getIndexAtHash = function () {
        var hash = location.hash.slice(1);
        if (hash) {
            return this._$characterProfile.filter("[data-name=" + hash + "]").index();
        }
        return 1;
    };
    CarouselCharacter.prototype._slideToHash = function () {
        this._carouselCharacter.slideTo(this._getIndexAtHash());
    };
    CarouselCharacter.prototype._toggleChange = function () {
        var notChange = this._$characterProfile
            .eq(this._carouselCharacter.realIndex)
            .hasClass("not-change");
        notChange ? this._fadeOutChange() : this._fadeInChange();
    };
    CarouselCharacter.prototype._fadeInOverlay = function () {
        var _this = this;
        TweenLite.set(this._$characterOverlay, { display: "block" });
        TweenLite.fromTo(this._$characterOverlay, 1, { autoAlpha: 0 }, {
            autoAlpha: 1,
            delay: 1,
            onComplete: function () {
                _this._$carouselCharacter.toggleClass("is-change");
                _this._fadeOutOverlay();
            },
        });
    };
    CarouselCharacter.prototype._fadeOutOverlay = function () {
        var _this = this;
        TweenLite.to(this._$characterOverlay, 1, {
            autoAlpha: 0,
            onComplete: function () {
                TweenLite.set(_this._$characterOverlay, { display: "none" });
            },
        });
    };
    CarouselCharacter.prototype._fadeInChange = function () {
        TweenLite.set(this._$characterChange, { display: "block" });
        TweenLite.to(this._$characterChange, 0.3, { autoAlpha: 1 });
    };
    CarouselCharacter.prototype._fadeOutChange = function () {
        var _this = this;
        TweenLite.to(this._$characterChange, 0.3, {
            autoAlpha: 0,
            onComplete: function () {
                TweenLite.set(_this._$characterChange, { display: "none" });
            },
        });
    };
    CarouselCharacter.prototype._smokeIn = function () {
        var _this = this;
        TweenLite.set(this._$characterSmoke, { autoAlpha: 0, rotation: 0 });
        TweenLite.to(this._$characterSmoke, 2, {
            autoAlpha: 1,
            rotation: 360,
            ease: Linear.easeNone,
            onComplete: function () {
                TweenLite.set(_this._$characterSmoke, { autoAlpha: 0, rotation: 0 });
            },
        });
    };
    return CarouselCharacter;
}());
export default CarouselCharacter;
