import { Power3, TimelineLite, TweenLite } from "gsap";
var Loading = /** @class */ (function () {
    function Loading(el) {
        var _this = this;
        console.log("[create] Loading");
        this._el = el;
        this.$gearGroup = $(this._el).find(".loading__gear-group");
        $(window).one("load", function () {
            var tl = new TimelineLite();
            var $characterChange = $("#js-character-change");
            tl
                .to(_this.$gearGroup, 1, { autoAlpha: 0 })
                .to(_this._el, 1, { autoAlpha: 0 });
            if ($characterChange.length !== 0) {
                TweenLite.set($characterChange, { autoAlpha: 0, scale: 1.5 });
                tl.to($characterChange, .6, { autoAlpha: 1, scale: 1, ease: Power3.easeInOut });
            }
        });
    }
    Loading.prototype.setup = function () {
        console.log("[call] Loading.setup");
    };
    Loading.prototype.teardown = function () {
        console.log("[call] Loading.teardown");
    };
    return Loading;
}());
export default Loading;
