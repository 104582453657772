var ToggleMenu = /** @class */ (function () {
    function ToggleMenu() {
        console.log("[create] ToggleMenu");
    }
    ToggleMenu.prototype.setup = function () {
        console.log("[call] ToggleMenu.setup");
        this._$root = $("#js-toggle-menu");
        this._$root.on("click", function () {
            $(document.body).toggleClass("is-menu-open");
        });
    };
    ToggleMenu.prototype.teardown = function () {
        delete this._$root;
    };
    return ToggleMenu;
}());
export default ToggleMenu;
