import AppFooter from "@/components/app-footer";
import Carousel from "@/components/carousel";
import CarouselCharacter from "@/components/carousel-character";
import ToggleMenu from "@/components/toggle-menu";
import { Power3, TweenLite } from "gsap";
var App = /** @class */ (function () {
    function App(el) {
        console.log("[create] App");
        this._el = el;
    }
    App.prototype.setup = function () {
        console.log("[call] App.setup");
        this._$goToTop = $("#js-go-to-top");
        new ToggleMenu().setup();
        new AppFooter().setup();
        new Carousel().setup();
        new CarouselCharacter().setup();
        this._setGoToTop();
        $(".colorbox").colorbox({
            iframe: true,
            width: window.innerWidth * .8,
            height: window.innerWidth * .5625 * .8,
            fixed: true,
            transition: "fade",
        });
        $(window).on("resize", function () {
            $.colorbox.resize({
                width: window.innerWidth * .8,
                height: window.innerWidth * .5625 * .8,
            });
        });
    };
    App.prototype.teardown = function () {
        console.log("[call] App.teardown");
    };
    App.prototype._setGoToTop = function () {
        this._$goToTop.on("click", function () {
            TweenLite.to(window, 0.6, { scrollTo: 0, ease: Power3.easeOut });
        });
    };
    return App;
}());
export default App;
