import { Swiper } from "swiper";
var Carousel = /** @class */ (function () {
    function Carousel() {
        console.log("[create] Carousel");
    }
    Carousel.prototype.setup = function () {
        var $carouselCampaign = $("#js-carousel-campaign");
        if ($carouselCampaign.length !== 0) {
            this._carouselCampaign = new Swiper($carouselCampaign[0], {
                slidesPerView: 3,
                spaceBetween: 12,
                centeredSlides: true,
                loop: true,
                breakpoints: {
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                },
                navigation: {
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                },
                autoplay: {
                    delay: 5000,
                },
            });
        }
    };
    Carousel.prototype.teardown = function () {
        this._carouselCampaign.destroy(true, true);
    };
    return Carousel;
}());
export default Carousel;
